import { Link } from 'react-router-dom';
import Img_Facebook from '../../../img/links/facebook2.png';
// import tiktok from '../../../img/links/tiktok.png';
import instagram from '../../../img/links/instagram.png';
import youtube from '../../../img/links/youtube.png';

import './LinkSocial.css';

function LinkSocial() {
  return (
    <div className="header__list-social-media">
      <div className="header__social-link-item">
        <Link
          to="https://www.instagram.com/softclean_24?utm_source=qr&igsh=aTR5bG96czFydXMz"
          title="instagram"
          alt="tiktok"
          target="_blank"
          className="header__instargam"
        >
          <img src={instagram} alt="instagram" />
        </Link>
      </div>
      <div className="header__social-link-item">
        <Link
          to="https://www.facebook.com/profile.php?id=61562821290742"
          target="_blank"
          alt="facebook"
          title="facebook"
          className="header_facebook"
        >
          <img src={Img_Facebook} alt="facebook" />
        </Link>
      </div>
      <div className="header__social-link-item">
        <Link
          to="http://www.youtube.com/@SoftClean-t6z"
          target="_blank"
          alt="youtube"
          title="youtube"
          className="header_facebook"
        >
          <img src={youtube} alt="facebook" />
        </Link>
      </div>
      {/* <div className="header__social-link-item">
        <Link
          to="#"
          target="_blank"
          alt="instagram"
          title="instagram"
          className="header_facebook"
        >
          <img src={instagram} alt="facebook" />
        </Link>
      </div> */}
    </div>
  );
}
export default LinkSocial;
