import VideoPlayerYoutube from 'react-player/youtube';

function ReactVideoPlayerYoutube() {
  return (
    <VideoPlayerYoutube
      playing={false}
      width="100%"
      height="100%"
      controls={true}
      url={'https://youtu.be/9cpzLJDgvJM?si=C87Qe06HLr-xbf5A'}
    />
  );
}
function ReactVideoPlayerYoutube2() {
  return (
    <VideoPlayerYoutube
      playing={false}
      width="100%"
      height="100%"
      controls={true}
      url={'https://youtu.be/9JebKuL7ieU?si=77aeXu9Upl_1-MWv'}
    />
  );
}

export { ReactVideoPlayerYoutube, ReactVideoPlayerYoutube2 };
