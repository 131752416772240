import { Link } from 'react-router-dom';
import './MyPhone.css';

function MyPhone(props) {
  const {
    classphonelink = 'header_phone_link',
    classphoneh3 = 'header_phone_h3',
  } = props;

  return (
    <Link to={'tel:+48576934663'} className={classphonelink}>
      <h3 {...props} className={classphoneh3} title="phone">
        +48 576934663
      </h3>
    </Link>
  );
}
export default MyPhone;
