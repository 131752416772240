import Header from '../1_Header/Container/Header';
import Karcher from '../1_Header/Karcher/Karcher';
import Main from '../2_Main/Container/Main';
import Footer from '../3_Footer/Footer';
import LineUp from '../LineUp/LineUp';
import LinkForma from '../LinkForma/LinkForma';
import WhatsApp from '../WhatsUp/WhatsappLink';

import css from './AllProject.module.css';
function AllProject() {
  return (
    <>
      <div className={css.body_wrapper}>
        <Header />
        <Karcher />

        <Main />
        <LinkForma />
        <WhatsApp />
        <LineUp />
        <Footer />
      </div>
    </>
  );
}
export default AllProject;
