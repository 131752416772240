import './WhatsappLink.css';
import { Link } from 'react-router-dom';
import whatsapp from '../../img/whatsup/whatsapp.png';

function WhatsApp() {
  return (
    <>
      <div className="whatsappLink">
        <Link to="https://wa.me/48576934663">
          <img src={whatsapp} alt="WhatsApp" title="WhatsApp" />
        </Link>
      </div>
    </>
  );
}

export default WhatsApp;
