import { useLayoutEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
//images
import foto1 from '../../../img/gallary/fotogallary14.jpg';
import foto2 from '../../../img/gallary/fotogallary16.jpg';
import foto3 from '../../../img/gallary/fotogallary17.jpg';
import foto4 from '../../../img/gallary/fotogallary18.jpg';
import foto5 from '../../../img/gallary/fotogallary19.jpg';
// import foto6 from '../../../img/gallary/fotogallary3.jpg';
import foto7 from '../../../img/gallary/fotogallary4.jpg';
import foto8 from '../../../img/gallary/fotogallary5.jpg';

import './General_img.css';

function BlockOneFromArticle1() {
  return (
    <div className="galarry-block_1">
      <img src={foto1} alt="foto1" className="fotoImgGallary" />
    </div>
  );
}

function BlockTwoFromArticle2() {
  return (
    <div className="galarry-block_2">
      <img src={foto2} alt="foto9" className="fotoImgGallary" />
    </div>
  );
}

function BlockOneFromArticle3() {
  return (
    <div className="galarry-block_3">
      <img src={foto3} alt="foto1" className="fotoImgGallary" />
    </div>
  );
}

function BlockOneFromArticle4() {
  return (
    <div className="galarry-block_4">
      <img src={foto4} alt="foto4" className="fotoImgGallary" />
    </div>
  );
}

function BlockOneFromArticle5() {
  return (
    <div className="galarry-block_5">
      <img src={foto5} alt="foto4" className="fotoImgGallary" />
    </div>
  );
}
// function BlockOneFromArticle6() {
//   return (
//     <div className="galarry-block_5">
//       <img src={foto6} alt="foto4" className="fotoImgGallary" />
//     </div>
//   );
// }
function BlockOneFromArticle7() {
  return (
    <div className="galarry-block_5">
      <img src={foto7} alt="foto4" className="fotoImgGallary" />
    </div>
  );
}
function BlockOneFromArticle8() {
  return (
    <div className="galarry-block_5">
      <img src={foto8} alt="foto4" className="fotoImgGallary" />
    </div>
  );
}
function SwiperArticles() {
  return (
    <Swiper
      spaceBetween={20}
      slidesPerView={2}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      navigation={true}
      modules={[Pagination, Navigation]}
      className="mySwiper"
    >
      <SwiperSlide>
        <BlockOneFromArticle1 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockTwoFromArticle2 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle3 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle4 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle5 />
      </SwiperSlide>
      {/* <SwiperSlide>
        <BlockOneFromArticle6 />
      </SwiperSlide> */}
      <SwiperSlide>
        <BlockOneFromArticle7 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle8 />
      </SwiperSlide>
    </Swiper>
  );
}

function SwiperArticles2() {
  return (
    <Swiper
      spaceBetween={20}
      slidesPerView={1}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      navigation={true}
      modules={[Pagination, Navigation]}
      className="mySwiper"
    >
      {/* <SwiperSlide>
        <BlockOneFromArticle6 />
      </SwiperSlide> */}
      <SwiperSlide>
        <BlockOneFromArticle7 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle8 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle1 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockTwoFromArticle2 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle3 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle4 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle5 />
      </SwiperSlide>
    </Swiper>
  );
}
// vieo

function TitleGallary() {
  const [isHeader, setIsHeader] = useState(false);

  useLayoutEffect(() => {
    if (window.innerWidth < 840) {
      setIsHeader(true);
    } else {
      setIsHeader(false);
    }
  }, [setIsHeader]);
  return (
    <>
      <div className="main__second-services main-second">
        <div className="main-title">
          <p>Nasze prace</p>
          <h2>Pranie tapicerki meblowej samochodowej, dywanów i wykładzin</h2>
        </div>
        {!isHeader ? (
          <>
            <SwiperArticles />
          </>
        ) : (
          <>
            <SwiperArticles2 />
          </>
        )}
      </div>
    </>
  );
}
export default TitleGallary;
