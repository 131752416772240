import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import foto from '../../../img/about/about2.jpg';
import './About.css';
import Karcher from '../../1_Header/Karcher/Karcher';
import WhatsApp from '../../WhatsUp/WhatsappLink';

function AboutTable() {
  return (
    <div className="about">
      <div className="about-title">
        <h1>O nas</h1>
      </div>
      <div className="container about-container">
        <div className="about__content">
          <div className="about__content-text">
            <h2>Drodzy klienci, jesteśmy firmą sprzątającą "SoftClean"</h2>
            <p>
              Firma <strong>"Softclean"</strong> to wiodąca firma w branży usług
              sprzątających, oferująca wysokiej jakości sprzątanie dla klientów
              komercyjnych i prywatnych. Założona z myślą o zapewnieniu
              najwyższych standardów czystości, firma szybko zdobyła reputację
              dzięki profesjonalizmowi i dbałości o szczegóły. Każdy pracownik
              Softclean przechodzi staranne szkolenie, aby zagwarantować
              skuteczne i bezpieczne sprzątanie przy użyciu nowoczesnego sprzętu
              i ekologicznych środków czystości.
            </p>
            <div className="about__content-img">
              <img src={foto} alt="О нас" />
            </div>
            <p>
              Podstawowa filozofia Softclean polega na zapewnieniu maksymalnego
              komfortu i zadowolenia klientów. Firma oferuje indywidualne
              podejście do każdego zlecenia, opracowując specjalne plany
              sprzątania, które uwzględniają wszystkie życzenia i potrzeby
              klientów. Pozwala to zapewnić nie tylko czystość, ale i zdrową
              atmosferę w pomieszczeniach.
            </p>
            <p>
              Nasza firma oferuje szeroką gamę usług, takich jak czyszczenie
              powierzchni, usuwanie plam, usuwanie nieprzyjemnych zapachów,
              dezynfekcja, odkurzanie i wiele innych. Każda usługa jest
              dostosowana do indywidualnych potrzeb klienta, aby zapewnić jak
              najlepsze efekty.
            </p>
            <p>
              Jedną z głównych zalet Softclean jest elastyczność i szybkość
              reagowania na potrzeby klientów. Firma zapewnia szybkie wykonanie
              zleceń w dogodnym dla klientów czasie, co pozwala zminimalizować
              niedogodności i przerwy w pracy. Softclean nieustannie doskonali
              swoje metody i technologie sprzątania, wprowadzając nowoczesne
              rozwiązania w celu zwiększenia efektywności i jakości swoich
              usług.
            </p>
            <p>
              <strong>Softclean</strong> dąży do tego, aby stać się liderem w
              branży sprzątającej, oferując innowacyjne i ekologiczne
              rozwiązania dla swoich klientów. Współpraca z Softclean to
              gwarancja nieskazitelnej czystości i wysokiego poziomu obsługi.
            </p>
            <p>Działamy na terenie Legnica i okolic.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function About() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <Karcher />

        <AboutTable />
        <WhatsApp />
        <Footer />
      </div>
    </>
  );
}
export default About;
