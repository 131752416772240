import { Navigation, Scrollbar, A11y } from 'swiper/modules';
import { useLayoutEffect, useState } from 'react';
import {
  ReactVideoPlayerYoutube,
  ReactVideoPlayerYoutube2,
} from '../../../UI/ReactVideoPlayer2/ReactVideoPlayer2';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './SwiperVideoGallery.css';
import VideoGallery from '../VideoGallary/Gallery';

function SwiperVideo2() {
  return (
    <Swiper
      modules={[Navigation, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1}
      navigation
      scrollbar={{ draggable: true }}
    >
      <SwiperSlide>
        <div className="videoplayeryoute">
          <ReactVideoPlayerYoutube />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="videoplayeryoute">
          <ReactVideoPlayerYoutube2 />
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

function SwiperVideoGallery() {
  const [isHeader, setIsHeader] = useState(false);

  useLayoutEffect(() => {
    if (window.innerWidth < 450) {
      setIsHeader(true);
    } else {
      setIsHeader(false);
    }
  }, [setIsHeader]);
  return (
    <>
      <div className="main__third-services main-second">
        {!isHeader ? (
          <>
            <VideoGallery />
          </>
        ) : (
          <>
            <SwiperVideo2 />
          </>
        )}
      </div>
    </>
  );
}

export default SwiperVideoGallery;
