import './Services.css';
import main1 from '../../../img/Services/foto15.jpg';
import main2 from '../../../img/Services/car4.jpg';
import main3 from '../../../img/Services/armchair.png';
import main4 from '../../../img/Services/foto14.jpg';
import main5 from '../../../img/Services/cornerclean.png';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <>
      <div className="main__first-services">
        <div className="main-title">
          <h1>
            <span className="main-services-h1span">PROFESJONALNE PRANIE</span>{' '}
            kanap, foteli, materacy, wózków, narożników, zasłon, rolet,
            wykładzin, dywanów, tapicerka samochodowa, tapicerki meblowej...
          </h1>
        </div>

        <div className="main__services">
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main1} alt="photo1" />
            </div>
            <h2>Pranie kanap</h2>
            <p className="main-services-price">od 100 zł</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main2} alt="photo1" />
            </div>
            <h2>tapicerka samochodowa</h2>
            <p className="main-services-price">od 150 zł</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main3} alt="photo1" />
            </div>
            <h2>Pranie foteli</h2>
            <p className="main-services-price">od 20 zł</p>
          </div>

          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main4} alt="photo1" />
            </div>
            <h2>Pranie materaców </h2>
            <p className="main-services-price">od 60 zł</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main5} alt="photo1" />
            </div>
            <h2> Pranie narożników </h2>
            <p className="main-services-price">od 150 zł</p>
          </div>
        </div>
        <div className="main__link-details">
          <Link to="/price">Szczegółowo...</Link>
        </div>
      </div>
    </>
  );
}
export default Services;
