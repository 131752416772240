import './LinkForma.css';
import message from '../../img/forma/mail-inbox-app.png';
import { useProject } from '../../tools/ProviderContext';

function LinkForma() {
  const { notScrollLineUp, closeBurgerMenu } = useProject();
  return (
    <>
      <div className="linkforma">
        <a
          href="#forma"
          alt="formalink"
          onClick={() => {
            notScrollLineUp();
            closeBurgerMenu();
          }}
        >
          <img src={message} alt="message" title="message" />
        </a>
      </div>
    </>
  );
}

export default LinkForma;
